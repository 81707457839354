import {createReducer, on} from '@ngrx/store';
import {uploadAssistanceReporting} from '../action/assistance.action';
import {DisputeInstance} from '../../../../modules/profile/models/dispute.model';

export const assistanceReducer = createReducer(
    {},
    on(uploadAssistanceReporting.setAssistanceReporting, (state: DisputeInstance, {data}) => ({
        ...state,
            data
    })),
    on(uploadAssistanceReporting.resetAssistanceReporting, (_state: DisputeInstance, {}) => ({}))
);
