import {createActionGroup, props} from '@ngrx/store';
import {StoreFeatureNames} from '../enums/store-feature-names';
import {DisputeInstance} from '../../../../modules/profile/models/dispute.model';

enum AssistanceAction {
    SET_ASSISTANCE_REPORTING = 'SetAssistanceReporting',
    RESET_ASSISTANCE_REPORTING = 'ResetAssistanceReporting',
}

export const uploadAssistanceReporting = createActionGroup({
    source: StoreFeatureNames.ASSISTANCE,
    events: {
        [AssistanceAction.SET_ASSISTANCE_REPORTING]: props<{ data: DisputeInstance}>(),
        [AssistanceAction.RESET_ASSISTANCE_REPORTING]: props<{ data: DisputeInstance }>()
    }
});
