import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, EventEmitter, Component, ChangeDetectionStrategy, Input, Output, ViewChild, NgModule } from '@angular/core';
import { Subject } from 'rxjs';
const _c0 = ["payPalButtonContainer"];
class ScriptService {
  constructor(zone) {
    this.zone = zone;
  }
  registerScript(url, globalVar, onReady) {
    const existingGlobalVar = window[globalVar];
    if (existingGlobalVar) {
      // global variable is present = script was already loaded
      this.zone.run(() => {
        onReady(existingGlobalVar);
      });
      return;
    }
    // prepare script elem
    const scriptElem = document.createElement('script');
    scriptElem.id = this.getElemId(globalVar);
    scriptElem.innerHTML = '';
    scriptElem.onload = () => {
      this.zone.run(() => {
        onReady(window[globalVar]);
      });
    };
    scriptElem.src = url;
    scriptElem.async = true;
    scriptElem.defer = true;
    // add script to header
    document.getElementsByTagName('head')[0].appendChild(scriptElem);
  }
  cleanup(globalVar) {
    // remove script from DOM
    const scriptElem = document.getElementById(this.getElemId(globalVar));
    if (scriptElem) {
      scriptElem.remove();
    }
  }
  getElemId(globalVar) {
    return `ngx-paypal-script-elem-${globalVar}`;
  }
}
/** @nocollapse */
ScriptService.ɵfac = function ScriptService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ScriptService)(i0.ɵɵinject(i0.NgZone));
};
/** @nocollapse */
ScriptService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ScriptService,
  factory: ScriptService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScriptService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.NgZone
    }];
  }, null);
})();
class PayPalScriptService {
  constructor(scriptService) {
    this.scriptService = scriptService;
    this.paypalWindowName = 'paypal';
  }
  registerPayPalScript(config, onReady) {
    this.scriptService.registerScript(this.getUrlForConfig(config), this.paypalWindowName, onReady);
  }
  destroyPayPalScript() {
    this.scriptService.cleanup(this.paypalWindowName);
  }
  getUrlForConfig(config) {
    const params = [{
      name: 'client-id',
      value: config.clientId
    }];
    if (config.locale) {
      params.push({
        name: 'locale',
        value: config.locale
      });
    }
    if (config.currency) {
      params.push({
        name: 'currency',
        value: config.currency
      });
    }
    if (config.commit) {
      params.push({
        name: 'commit',
        value: config.commit
      });
    }
    if (config.vault) {
      params.push({
        name: 'vault',
        value: config.vault
      });
    }
    if (config.intent) {
      params.push({
        name: 'intent',
        value: config.intent
      });
    }
    if (config.funding) {
      params.push({
        name: 'components',
        value: 'buttons,funding-eligibility'
      });
    }
    if (config.extraParams) {
      params.push(...config.extraParams);
    }
    return `https://www.paypal.com/sdk/js${this.getQueryString(params)}`;
  }
  getQueryString(queryParams) {
    let queryString = '';
    for (let i = 0; i < queryParams.length; i++) {
      const queryParam = queryParams[i];
      if (i === 0) {
        queryString += '?';
      } else {
        queryString += '&';
      }
      queryString += `${queryParam.name}=${queryParam.value}`;
    }
    return queryString;
  }
}
/** @nocollapse */
PayPalScriptService.ɵfac = function PayPalScriptService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PayPalScriptService)(i0.ɵɵinject(ScriptService));
};
/** @nocollapse */
PayPalScriptService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: PayPalScriptService,
  factory: PayPalScriptService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PayPalScriptService, [{
    type: Injectable
  }], function () {
    return [{
      type: ScriptService
    }];
  }, null);
})();
class NgxPaypalComponent {
  constructor(paypalScriptService, cdr, ngZone) {
    this.paypalScriptService = paypalScriptService;
    this.cdr = cdr;
    this.ngZone = ngZone;
    /**
     * If enabled, paypal SDK script will be loaded. Useful if you want to have multiple PayPal components on the same page
     * sharing base configuration. In such a case only a single component may register script.
     */
    this.registerScript = true;
    /**
     * Emitted when paypal script is loaded
     */
    this.scriptLoaded = new EventEmitter();
    this.ngUnsubscribe = new Subject();
    /**
     * Flag that indicates if paypal should be initialized (required for handling script load events and availability of DOM element)
     */
    this.initializePayPal = true;
  }
  set payPalButtonContainer(content) {
    this.payPalButtonContainerElem = content;
  }
  ngOnChanges(changes) {
    if (!this.payPalButtonContainerId) {
      this.payPalButtonContainerId = this.generateElementId();
    }
    // first time config setup
    const config = this.config;
    if (changes.config.isFirstChange()) {
      if (config && this.registerScript) {
        this.initPayPalScript(config, payPal => {
          // store reference to paypal global script
          this.payPal = payPal;
          this.doPayPalCheck();
        });
      }
    }
    // changes to config
    if (!changes.config.isFirstChange()) {
      this.reinitialize(config);
    }
  }
  ngOnDestroy() {
    this.paypalScriptService.destroyPayPalScript();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  ngAfterViewInit() {
    this.doPayPalCheck();
  }
  customInit(payPal) {
    this.payPal = payPal;
    this.doPayPalCheck();
  }
  reinitialize(config) {
    this.config = config;
    this.payPal = undefined;
    this.paypalScriptService.destroyPayPalScript();
    this.payPalButtonContainerId = this.generateElementId();
    this.initializePayPal = true;
    if (this.payPalButtonContainerElem) {
      try {
        while (this.payPalButtonContainerElem.nativeElement.firstChild) {
          this.payPalButtonContainerElem.nativeElement.removeChild(this.payPalButtonContainerElem.nativeElement.firstChild);
        }
      } catch (error) {
        console.error(error);
      }
    }
    this.cdr.detectChanges();
    if (this.config) {
      if (!this.payPal) {
        this.initPayPalScript(this.config, payPal => {
          // store reference to paypal global script
          this.payPal = payPal;
          this.doPayPalCheck();
        });
      } else {
        this.doPayPalCheck();
      }
    }
  }
  doPayPalCheck() {
    if (this.initializePayPal && this.config && this.payPal && this.payPalButtonContainerElem) {
      // make sure that id is also set
      if (this.payPalButtonContainerElem.nativeElement.id) {
        this.initializePayPal = false;
        this.initPayPal(this.config, this.payPal);
      }
    }
  }
  initPayPalScript(config, initPayPal) {
    this.paypalScriptService.registerPayPalScript({
      clientId: config.clientId,
      locale: config.advanced?.locale,
      commit: config.advanced && config.advanced.commit ? config.advanced.commit : undefined,
      currency: config.currency,
      vault: config.vault,
      intent: config.intent,
      funding: config.fundingSource != undefined || config.fundingSource != null ? true : false,
      extraParams: config.advanced && config.advanced.extraQueryParams ? config.advanced.extraQueryParams : []
    }, paypal => {
      this.scriptLoaded.next(paypal);
      initPayPal(paypal);
    });
  }
  generateElementId() {
    return `ngx-captcha-id-${this.generateGuid()}`;
  }
  initPayPal(config, paypal) {
    // Running outside angular zone prevents infinite ngDoCheck lifecycle calls
    this.ngZone.runOutsideAngular(() => {
      // https://developer.paypal.com/docs/checkout/integrate/#2-add-the-paypal-script-to-your-web-page
      const createOrder = (data, actions) => {
        return this.ngZone.run(() => {
          if (config.createOrderOnClient && config.createOrderOnServer) {
            throw Error(`Both 'createOrderOnClient' and 'createOrderOnServer' are defined.
                    Please choose one or the other.`);
          }
          if (!config.createOrderOnClient && !config.createOrderOnServer) {
            throw Error(`Neither 'createOrderOnClient' or 'createOrderOnServer' are defined.
                    Please define one of these to create order.`);
          }
          if (config.createOrderOnClient) {
            return actions.order.create(config.createOrderOnClient(data));
          }
          if (config.createOrderOnServer) {
            return config.createOrderOnServer(data);
          }
          throw Error(`Invalid state for 'createOrder'.`);
        });
      };
      const createSubscription = (data, actions) => {
        return this.ngZone.run(() => {
          if (config.createSubscriptionOnClient) {
            return actions.subscription.create(config.createSubscriptionOnClient(data));
          }
          return;
        });
      };
      const onShippingChange = (data, actions) => {
        return this.ngZone.run(() => {
          if (config.onShippingChange) {
            return config.onShippingChange(data, actions);
          }
        });
      };
      const buttonsConfig = {
        style: config.style,
        fundingSource: undefined,
        onApprove: (data, actions) => {
          return this.ngZone.run(() => {
            if (config.onApprove) {
              config.onApprove(data, actions);
            }
            // capture on server
            if (config.authorizeOnServer) {
              return config.authorizeOnServer(data, actions);
            }
            // capture on client
            const onClientAuthorization = config.onClientAuthorization;
            if (onClientAuthorization) {
              actions.order.capture().then(details => {
                this.ngZone.run(() => {
                  onClientAuthorization(details);
                });
              });
            }
          });
        },
        onError: error => {
          this.ngZone.run(() => {
            if (config.onError) {
              config.onError(error);
            }
          });
        },
        onCancel: (data, actions) => {
          this.ngZone.run(() => {
            if (config.onCancel) {
              config.onCancel(data, actions);
            }
          });
        },
        onClick: (data, actions) => {
          this.ngZone.run(() => {
            if (config.onClick) {
              config.onClick(data, actions);
            }
          });
        },
        onInit: (data, actions) => {
          this.ngZone.run(() => {
            if (config.onInit) {
              config.onInit(data, actions);
            }
          });
        },
        // Add the functions if they've been created in the config object
        // The API only allows one of the two to be set
        ...((config.createOrderOnClient || config.createOrderOnServer) && {
          createOrder
        }),
        ...(config.createSubscriptionOnClient && {
          createSubscription
        }),
        // The onShippingChange callback cannot be used with subscriptions
        // so we only add it if it is set
        ...(config.onShippingChange && {
          onShippingChange
        })
      };
      let fundSource = undefined;
      switch (config.fundingSource) {
        case "PAYPAL":
          fundSource = paypal.FUNDING.PAYPAL;
          break;
        case "CARD":
          fundSource = paypal.FUNDING.CARD;
          break;
        case "PAYLATER":
          fundSource = paypal.FUNDING.PAYLATER;
          break;
        case "CREDIT":
          fundSource = paypal.FUNDING.CREDIT;
          break;
        case "VENMO":
          fundSource = paypal.FUNDING.VENMO;
          break;
        default:
          break;
      }
      if (fundSource != undefined) {
        buttonsConfig.fundingSource = fundSource;
        if (config.fundingSource !== "PAYPAL") delete buttonsConfig.style?.color;
      }
      paypal.Buttons(buttonsConfig).render(`#${this.payPalButtonContainerId}`);
    });
  }
  generateGuid() {
    let d = new Date().getTime(),
      d2 = performance && performance.now && performance.now() * 1000 || 0;
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
      let r = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c == "x" ? r : r & 0x7 | 0x8).toString(16);
    });
  }
}
/** @nocollapse */
NgxPaypalComponent.ɵfac = function NgxPaypalComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxPaypalComponent)(i0.ɵɵdirectiveInject(PayPalScriptService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
/** @nocollapse */
NgxPaypalComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: NgxPaypalComponent,
  selectors: [["ngx-paypal"]],
  viewQuery: function NgxPaypalComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.payPalButtonContainer = _t.first);
    }
  },
  inputs: {
    config: "config",
    registerScript: "registerScript"
  },
  outputs: {
    scriptLoaded: "scriptLoaded"
  },
  features: [i0.ɵɵNgOnChangesFeature],
  decls: 2,
  vars: 1,
  consts: [["payPalButtonContainer", ""], [3, "id"]],
  template: function NgxPaypalComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "div", 1, 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("id", ctx.payPalButtonContainerId);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPaypalComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      selector: "ngx-paypal",
      template: `
    <div #payPalButtonContainer [id]="payPalButtonContainerId"></div>
  `
    }]
  }], function () {
    return [{
      type: PayPalScriptService
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: i0.NgZone
    }];
  }, {
    config: [{
      type: Input
    }],
    registerScript: [{
      type: Input
    }],
    scriptLoaded: [{
      type: Output
    }],
    payPalButtonContainer: [{
      type: ViewChild,
      args: ["payPalButtonContainer", {
        static: false
      }]
    }]
  });
})();
class NgxPayPalModule {}
/** @nocollapse */
NgxPayPalModule.ɵfac = function NgxPayPalModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxPayPalModule)();
};
/** @nocollapse */
NgxPayPalModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxPayPalModule
});
/** @nocollapse */
NgxPayPalModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [ScriptService, PayPalScriptService],
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPayPalModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [NgxPaypalComponent],
      exports: [NgxPaypalComponent],
      providers: [ScriptService, PayPalScriptService]
    }]
  }], null, null);
})();

/** Public API */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxPayPalModule, NgxPaypalComponent, PayPalScriptService };

